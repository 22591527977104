<template>
  <div
    :class="{
      'bk-paragraph-region-affected': isHidden === true,
    }"
  >
    <ParagraphChartBarHorizontalDistribution
      v-if="isDistribution"
      :table="table"
      :distribution="distribution"
      :color-palette="colorPalette"
      :value-suffix="valueSuffix"
      :value-prefix="valuePrefix"
      :values-on-hover="valuesOnHover"
      :decimal-points="decimalPoints"
    />
    <ParagraphChartBarHorizontalSingle
      v-else
      :table="table"
      :distribution="distribution"
      :color-palette="colorPalette"
      :value-suffix="valueSuffix"
      :value-prefix="valuePrefix"
      :decimal-points="decimalPoints"
    />
    <div v-if="axisLabel" class="font-medium pt-5 text-center text-base">
      {{ axisLabel }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChartBarDistribution, ChartColorPalette } from '~/helpers/charts'

const props = defineProps<{
  table: string[][]
  distribution: ChartBarDistribution
  colorPalette: ChartColorPalette
  valuePrefix?: string
  valueSuffix?: string
  valuesOnHover?: boolean
  decimalPoints: number
  axisLabel?: string
  isHidden?: boolean
}>()

const isDistribution = computed(() => props.table[0]?.length > 2)
</script>
