<template>
  <div
    class="container mx-auto"
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
    ]"
  >
    <div class="grid-container">
      <h2
        v-blokkli-editable:field_title
        class="text-3xl md:text-4xl mb-10 lg:mb-14 leading-10 col-span-4 sm:col-span-6 md:col-start-8 md:col-span-8 lg:col-start-2 lg:col-span-10"
      >
        {{ title }}
      </h2>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      <div
        v-for="(fact, index) in factFigures"
        :key="index"
        class="text-center p-4"
      >
        <div
          class="text-7xl md:text-9xl font-bold"
          :class="options.colorPalette"
        >
          {{ fact.number }}
        </div>
        <div class="mt-3 font-bold text-xl md:text-2xl">
          {{ fact.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphInfoBoxFragment } from '#graphql-operations'

defineProps<{
  title?: string
  factFigures?: ParagraphInfoBoxFragment['factFigures']
}>()

const { options } = defineBlokkli({
  bundle: 'infobox',
  globalOptions: ['spacing'],
  options: {
    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'sand',
      displayAs: 'colors',
      options: {
        sand: { label: 'Sand', class: 'bg-sand' },
        violett: { label: 'Violett', class: 'bg-violett' },
      },
    },
  },
})
</script>

<style lang="postcss">
.sand {
  @apply text-sand;
}
.violett {
  @apply text-violett;
}
</style>
