<template>
  <div
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
    ]"
    class="container mx-auto"
  >
    <div class="grid-container">
      <h2
        v-blokkli-editable:field_title
        class="text-3xl md:text-4xl mb-10 lg:mb-14 leading-10 col-span-4 sm:col-span-6 md:col-start-8 md:col-span-8 lg:col-start-2 lg:col-span-10"
      >
        {{ title }}
      </h2>
    </div>
    <div class="relative w-full">
      <div
        v-for="(milestone, index) in milestones"
        :key="index"
        class="milestone before:content-[''] flex flex-col items-center"
      >
        <div
          class="milestone-content pt-8 flex flex-col items-center relative md:w-full"
        >
          <h2
            :class="options.colorPalette"
            class="text-7xl md:text-9xl font-bold"
          >
            {{ milestone.year }}
          </h2>
          <div
            class="size-4 bg-white border-2 border-gray-300 rounded-full hidden md:flex absolute left-[18rem] top-1/2 -translate-y-1/2"
          />
          <div
            class="milestone-content-text md:absolute md:w-[35rem] md:left-0 md:top-1/2 md:-translate-y-1/2 md:before:content-[''] md:flex md:flex-row md:items-center md:justify-start"
          >
            <div
              class="mt-2 text-gray-700 leading-relaxed flex flex-col"
              v-html="milestone.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphMilestonesFragment } from '#graphql-operations'

defineProps<{
  title?: string
  milestones?: ParagraphMilestonesFragment['milestones']
}>()

const { options } = defineBlokkli({
  bundle: 'milestones',
  globalOptions: ['spacing'],
  options: {
    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'sand',
      displayAs: 'colors',
      options: {
        sand: { label: 'Sand', class: 'bg-sand' },
        violett: { label: 'Violett', class: 'bg-violett' },
      },
    },
  },
})
</script>

<style lang="postcss">
.milestone {
  &:before {
    @apply hidden;
  }

  & ~ .milestone {
    &:before {
      @apply w-0.5 h-20 bg-gray-300 flex mt-8;
    }
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  .milestone-content-text {
    &:before {
      @apply w-36 h-0.5 bg-gray-300 flex order-3 ml-8;
    }

    > div {
      @apply md:w-[17rem];
    }
  }

  &:nth-child(even) {
    h2 {
      @apply text-gray-500;
    }

    .milestone-content {
      div {
        @apply right-[18rem] left-auto;
      }
      .milestone-content-text {
        @apply md:right-0 md:left-auto md:justify-end;

        &:before {
          @apply flex order-none mr-8;
        }
      }
    }
  }

  &:last-child {
    @apply pb-20;
  }
}
</style>
